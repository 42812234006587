

export function generateDynamicColorSchemeCSS(scheme: {[tint: string]: string}): string {
  const variables = Object.entries(scheme).map(([tint, hex]) => {
    return generateColorVariable(tint, hex)
  })
  return `:root {${variables.join(' ')}}`
}

function generateColorVariable(tint: string, hex: string) {
  const [r, g, b] = hexToRGB(hex)
  return `${variableName(tint)}: ${r} ${g} ${b};`
}
function hexToRGB(hex: string): [number, number, number] {
  let color = hex.replace(/#/g, "")
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  return [r, g, b]
}
function variableName(tint: string): string {
  return `--color-brand-${tint}`
}