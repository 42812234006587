import React, {FC, useEffect, useState} from "react";
import {Invite} from "../../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircleNotch, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import Confetti from "react-confetti";
import {useApiCall} from "../../api/api";

export const AvailabilityWizard: FC<{invite: Invite, close: () => void}> = (props) => {
  const people = getPeople(props.invite)
  const [confirmedPeople, setConfirmedPeople] = React.useState<string[]>(people)
  const [confirmedPeopleConfirmed, setConfirmedPeopleConfirmed] = React.useState<boolean>(props.invite.has_confirmed_amount && props.invite.has_confirmed_dinner_preferences === props.invite.is_invited_to_dinner)
  const [isSavingConfirmation, setIsSavingConfirmation] = React.useState<boolean>(false)
  const [dinnerPreferences, setDinnerPreferences] = React.useState<{[key: string]: {[key: string]: {[key: string]: string}}}>({})
  const {confirmAvailability, confirmDinnerPreferences, addNote} = useApiCall()
  const confirmPeople = async () => {
    setIsSavingConfirmation(true)
    await confirmAvailability(props.invite.code, confirmedPeople.length)
    if (confirmedPeople.length !== props.invite.amount) {
      await addNote(props.invite.code, `Alleen ${confirmedPeople.join(', ')} aanwezig`)
    }
    setIsSavingConfirmation(false)
    setConfirmedPeopleConfirmed(true)
  }

  const [hasConfirmedDinnerPreferences, setHasConfirmedDinnerPreferences] = React.useState<boolean>(props.invite.has_confirmed_dinner_preferences)
  const [isSavingDinnerPrefs, setIsSavingDinnerPrefs] = React.useState<boolean>(false)
  const confirmDinnerPrefs = async () => {
    setIsSavingDinnerPrefs(true)
    await confirmDinnerPreferences(props.invite.code, dinnerPreferences)
    setIsSavingDinnerPrefs(false)
    setHasConfirmedDinnerPreferences(true)
  }

  const moreInfo = () => {
    props.close()
    setTimeout(() => {
      document.getElementById('info')?.scrollIntoView({behavior: 'smooth'})
    }, 100)
  }

  const isFinished = confirmedPeopleConfirmed && (props.invite.is_invited_to_dinner === hasConfirmedDinnerPreferences || confirmedPeople.length === 0)

  return <div className={"fixed top-0 left-0 h-screen w-screen z-30 bg-offwhite flex items-stretch"}>
    {isFinished && confirmedPeople.length > 0 && <Confetti colors={['#d1dcee', '#ffc192', '#ffaeb4', '#c3dbff', '#fee7b3', '#d9dfa5', '#f7dfd3']} />}
    <div className={"flex-1 relative"}

         style={{backgroundImage: 'url("/papier.jpg")', backgroundSize: 'cover', backgroundPosition: 'center'}}>
      {/* Background*/}
      <img src="/menu%20logo.png" loading={'lazy'} alt="💍" className={"hidden md:block md:absolute md:top-8 md:left-8 h-24 w-24 z-10"}/>
      <img src="/rear-invite-card.png" alt="" className={'max-h-[80vh] hidden md:block md:w-2/3 xl:w-auto rounded-xl shadow-2xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-12'}/>
    </div>
    <div
      className={"w-full md:w-2/3 lg:w-1/2 xl:w-2/5 shadow-xl space-y-8 font-serif"}>
      <div className={"px-4 py-4 lg:px-8 xl:px-16 h-screen overflow-y-scroll"}>
        <img src="/menu%20logo.png" loading={'lazy'} alt="💍" className={"md:hidden h-20 w-20"}/>
        {/* Content*/}
        {!confirmedPeopleConfirmed && <div className={"min-h-full flex flex-col items-stretch justify-center"}>
          <h1 className={"font-medium text-2xl mb-4"}>{props.invite.amount === 1 ? 'Ben je' : 'Zijn jullie'} er bij?</h1>
          <div className={"space-y-3"}>
            {people.map((name, index) => <label key={index}
                                                className={`flex items-center space-x-4 px-4 py-3 cursor-pointer hover:bg-blue-50 rounded-lg border-2 ${confirmedPeople.includes(name) ? 'border-blue-900' : 'border-slate-300'}`}>
              <input type="checkbox" className={"h-4 w-4"} checked={confirmedPeople.includes(name)}
                     onChange={(e) => setConfirmedPeople(p => e.target.checked ? [...p, name] : p.filter(n => n !== name))}/>
              <span className={"text-lg"}>{name}</span>
            </label>)}
          </div>
          <button
            className={"rounded-lg px-4 py-3 text-white bg-blue-900 font-sans flex items-center justify-center text-lg font-medium mt-8"}
            onClick={confirmPeople}>
            <FontAwesomeIcon icon={isSavingConfirmation ? faCircleNotch : faCheckCircle} spin={isSavingConfirmation} className={"mr-2"}/>
            <span>Aanwezigheid bevestigen</span>
          </button>
          <Remarks invite={props.invite} />
        </div>}
        {confirmedPeopleConfirmed && props.invite.is_invited_to_dinner !== hasConfirmedDinnerPreferences && !isFinished && <>
          <h1 className={"font-medium text-2xl mb-4"}>Wat {props.invite.amount === 1 ? 'wil je' : 'willen jullie'} eten?</h1>
          <div className={"space-y-3"}>
            {confirmedPeople.map((name, index) => <div key={index}>
              <h2 className={"font-medium text-2xl"}>{name}</h2>
              <DinnerOptions invite={props.invite} selectedOptions={dinnerPreferences[name] ?? {}} optionsChanged={(newOptions) => setDinnerPreferences(p => ({...p, [name]: newOptions}))}/>
            </div>)}
          </div>
          <button
            className={"rounded-lg px-4 py-3 text-white bg-blue-900 font-sans flex items-center justify-center text-lg font-medium mt-8"}
            onClick={confirmDinnerPrefs}>
            <FontAwesomeIcon icon={isSavingDinnerPrefs ? faCircleNotch : faCheckCircle} spin={isSavingDinnerPrefs} className={"mr-2"}/>
            <span>Menuopties bevestigen</span>
          </button>
          <Remarks invite={props.invite} />
        </>}
        {isFinished && <div className={"min-h-full flex flex-col items-stretch justify-center"}>
          {confirmedPeople.length === 0 ? <>
            <h1 className={"font-medium text-2xl"}>Bedankt voor de update</h1>
            <p className={'my-4'}>Jammer dat {props.invite.amount === 1 ? 'je' : 'jullie'} er niet bij kunnen zijn.</p>
          </> : <>
            <h1 className={"font-medium text-2xl"}>Dankjewel!</h1>
            <p className={'my-4'}>Wij hebben er super veel zin in. {props.invite.amount === 1 ? 'Jij' : 'Jullie'} ook?</p>
            <p className={'my-4'}>Meer informatie over de locatie en het dagprogramma?</p>
            <button onClick={moreInfo}
               className={"rounded-lg px-3 py-2 text-white bg-blue-900 font-sans flex items-center justify-center text-lg font-medium"}>Informatie
              over de dag</button>
            <Remarks invite={props.invite}/>
          </>}
        </div>}
      </div>
    </div>
  </div>
}

const getPeople = (invite: Invite) => {
  return invite.names.split(/\sen\s|\s&\s|,\s/).map(name => name.trim())
}

const Remarks: FC<{invite: Invite}> = (props) => {
  const [remark, setRemark] = useState<string>("")
  const [phase, setPhase] = useState<"idle" | "loading" | "success">("idle")
  const [isDirty, setIsDirty] = useState(false)
  const isLoading = phase === "loading"
  const {addNote} = useApiCall()
  const confirm = async () => {
    setPhase('loading')
    addNote(props.invite.code, remark)
    setPhase('success')
  }
  useEffect(() => {
    if (remark.length > 0) {
      setIsDirty(true)
      setPhase('idle')
    }
  }, [remark]);
  return <div className={"space-y-3 mt-8 pt-8 border-t border-slate-300"}>
    <h2 className={"text-lg"}>Opmerkingen?</h2>
    <textarea className={'w-full bg-white border border-slate-300 focus:border-blue-900 rounded-lg px-4 py-3'}
              value={remark} onChange={(e) => setRemark(e.target.value)}/>
    {phase === 'success' ? <div>
      <p className={"px-4 py-2 text-blue-900 font-sans flex items-center font-medium"}>Verstuurd!</p>
    </div> : <button
      disabled={isLoading || !isDirty}
      className={"rounded-lg px-4 py-2 text-white bg-blue-900 disabled:bg-slate-400 font-sans flex items-center justify-center font-medium"}
      onClick={confirm}>
      <FontAwesomeIcon icon={isLoading ? faCircleNotch : faPaperPlane} spin={isLoading} className={"mr-2"}/>
      <span>Versturen</span>
    </button>}
  </div>
}


const DinnerOptions: FC<{invite: Invite, selectedOptions: {[key: string]: {[key: string]: string}}, optionsChanged: (newOptions: {[key: string]: {[key: string]: string}}) => void}> = (props) => {
  const isSelected = (course: string, option: string) => {
    return props.selectedOptions[course]?.['option'] === option
  }
  const selectOption = (course: string, option: string) => {
    props.optionsChanged({...props.selectedOptions, [course]: {...(props.selectedOptions[course] ?? {}), option: option}})
  }

  const isVega = (course: string) => {
    return props.selectedOptions[course]?.['vega'] === 'true'
  }
  const selectVega = (course: string, vega: boolean) => {
    props.optionsChanged({...props.selectedOptions, [course]: {...(props.selectedOptions[course] ?? {}), vega: vega ? 'true' : 'false'}})
  }

  return <div className={"mb-8"}>
    <h3 className={"text-lg mt-3 mb-2"}>Voorgerecht</h3>
    <DinnerOptionRadio title={'Coquille'}
                       description={'DUN GESNEDEN COQUILLE | RIVIERKREEFT | CITRUS | TIJMMAYONAISE | MOSTERDZAAD'}
                       vegaAvail={true}
                       selected={isSelected('Voorgerecht', 'Coquille')}
                       onClick={() => selectOption('Voorgerecht', 'Coquille')}
                       vega={isVega('Voorgerecht')}
                       vegaChanged={(newVega) => selectVega('Voorgerecht', newVega)}
    />
    <DinnerOptionRadio title={'Runderroulleau'}
                       description={'SALSA TOMAAT | POPCORN | KORIANDER | PEPER | KAPPERTJES | MOSTERDMAYONAISE'}
                       vegaAvail={true}
                       selected={isSelected('Voorgerecht', 'Runderroulleau')}
                       onClick={() => selectOption('Voorgerecht', 'Runderroulleau')}
                       vega={isVega('Voorgerecht')}
                       vegaChanged={(newVega) => selectVega('Voorgerecht', newVega)}
    />
    <h3 className={"text-lg mt-3 mb-2"}>Hoofdgerecht</h3>
    <DinnerOptionRadio title={'Zalm'}
                       description={'KROKANTE SOFTSHELL KRAB | GNOCCHI | BISQUE | SPINAZIE | GROENE ASPERGE | TOMATEN CONCASSÉ'}
                       vegaAvail={true}
                       selected={isSelected('Hoofdgerecht', 'Zalm')}
                       onClick={() => selectOption('Hoofdgerecht', 'Zalm')}
                       vega={isVega('Hoofdgerecht')}
                       vegaChanged={(newVega) => selectVega('Hoofdgerecht', newVega)}
    />
    <DinnerOptionRadio title={'Rundersteak'}
                       description={'MOUSELINE | LANGRES | BLEEKSELDERIJ | WITLOF | BIMI | ZWARTE PEPERJUS'}
                       vegaAvail={true}
                       selected={isSelected('Hoofdgerecht', 'Rundersteak')}
                       onClick={() => selectOption('Hoofdgerecht', 'Rundersteak')}
                       vega={isVega('Hoofdgerecht')}
                       vegaChanged={(newVega) => selectVega('Hoofdgerecht', newVega)}
    />
    <h3 className={"text-lg mt-3 mb-2"}>Nagerecht</h3>
    <DinnerOptionRadio title={'Kaas'}
                       description={'KAAS | 4 SOORTEN | KLETZENBROOD | PEER | PORT'}
                       vegaAvail={false}
                       selected={isSelected('Nagerecht', 'Kaas')}
                       onClick={() => selectOption('Nagerecht', 'Kaas')}
                       vega={isVega('Nagerecht')}
                       vegaChanged={(newVega) => selectVega('Nagerecht', newVega)}
    />
    <DinnerOptionRadio title={'Espresso Martini Dessert'}
                       description={'WITTE CHOCOLADE | CACAO'}
                       vegaAvail={false}
                       selected={isSelected('Nagerecht', 'Espresso Martini Dessert')}
                       onClick={() => selectOption('Nagerecht', 'Espresso Martini Dessert')}
                       vega={isVega('Nagerecht')}
                       vegaChanged={(newVega) => selectVega('Nagerecht', newVega)}
    />
  </div>
}

const DinnerOptionRadio: FC<{ title: string, description: string, selected: boolean, onClick: () => void, vegaAvail: boolean, vega: boolean, vegaChanged: (newVega: boolean) => void }> = props => {
  return <label
    className={`flex items-center space-x-4 px-4 py-3 mb-2 cursor-pointer hover:bg-blue-50 rounded-lg border-2 ${props.selected ? 'border-blue-900' : 'border-slate-300'}`}>
    <input type="radio" className={"h-4 w-4"} checked={props.selected}
           onChange={props.onClick}/>
    <div>
      <div className={"text-lg font-bold tracking-wider"}>{props.title}</div>
      <div className={"text-sm tracking-wider"}>{props.description}</div>
      {props.vegaAvail && <label className={'flex items-center mt-2 text-sm'}>
        <input type={'checkbox'} className={"mr-2"} checked={props.vega}
               onChange={() => props.vegaChanged(!props.vega)}/>
        Vegetarisch alternatief
      </label>}
    </div>
  </label>
}
