import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRoute} from "@fortawesome/free-solid-svg-icons";
import {Invite} from "../../api/dto";

export const Info: FC<{invite: Invite|null}> = (props) => {
  const gridStyling = {
    0: 'grid grid-cols-1 lg:grid-cols-1', // deze hoor er niet bij, maar ik heb ocd
    1: 'grid grid-cols-1 lg:grid-cols-1',
    2: 'grid grid-cols-1 lg:grid-cols-2',
    3: 'grid grid-cols-1 lg:grid-cols-3',
    4: 'grid grid-cols-1 lg:grid-cols-4',
    5: 'grid grid-cols-1 lg:grid-cols-5',
  }[
    (props.invite?.is_invited_to_ceremony || props.invite === null ? 3 : 0) +
    (props.invite?.is_invited_to_dinner || props.invite === null ? 1 : 0) +
    (props.invite?.is_invited_to_party || props.invite === null ? 1 : 0)
  ]
  console.log(
    (props.invite?.is_invited_to_ceremony || props.invite === null ? 3 : 0) +
    (props.invite?.is_invited_to_dinner || props.invite === null ? 1 : 0) +
    (props.invite?.is_invited_to_party || props.invite === null ? 1 : 0)
  )
  console.log(props.invite?.is_invited_to_party || props.invite === null)
  return <>
    {/* ALGEMENE INFO*/}
    <section className={"px-8 py-16 bg-[#fcf8f9]"}>
      <div className={`max-w-screen-2xl mx-auto gap-8 ${gridStyling}`} id={'info'}>
        {(props.invite?.is_invited_to_ceremony || props.invite === null) && <>
          <div className={"flex flex-col items-center"}>
            <img alt={""} src={"/icon-vroem.png"} className={"h-32 m-4"}/>
            <span className={"text-sm"}>14:30</span>
            <h2 className={"font-serif font-bold text-xl mb-8"}>Aankomst</h2>
            <p>
              Gasten zijn welkom vanaf 14:30, de ceremonie begint om 15:00.
            </p>
          </div>
          <div className={"flex flex-col items-center"}>
            <img alt={""} src={"/icon-rings.png"} className={"h-32 m-4"}/>
            <span className={"text-sm"}>15:00</span>
            <h2 className={"font-serif font-bold text-xl mb-8"}>Ceremonie</h2>
            <p>
              Om 15:00 geven we elkaar het ja-woord.
            </p>
          </div>
          <div className={"flex flex-col items-center"}>
            <img alt={""} src={"/icon-proost.png"} className={"h-32 m-4"}/>
            <span className={"text-sm"}>16:00</span>
            <h2 className={"font-serif font-bold text-xl mb-8"}>Receptie</h2>
            <p>
              Na de ceremonie is het tijd om de taart aan te snijden en te proosten.
            </p>
          </div>
        </>}
        {(props.invite?.is_invited_to_dinner || props.invite === null) && <>
          <div className={"flex flex-col items-center"}>
            <img alt={""} src={"/icon-tafel.png"} className={"h-32 m-4"}/>
            <span className={"text-sm"}>18:00</span>
            <h2 className={"font-serif font-bold text-xl mb-8"}>Diner</h2>
            <p>
              Na de receptie is het tijd voor een gezellig 3-gangen diner.
            </p>
          </div>
        </>}
        {(props.invite?.is_invited_to_party || props.invite === null) && <>
          <div className={"flex flex-col items-center"}>
            <img alt={""} src={"/icon-dans.png"} className={"h-32 m-4"}/>
            <span className={"text-sm"}>20:00</span>
            <h2 className={"font-serif font-bold text-xl mb-8"}>Feest</h2>
            <p>
              Haal je dansschoenen uit de kast, want vanaf 20:00 tot 01:00 is het tijd om te feesten!
            </p>
          </div>
        </>}
      </div>
    </section>
  </>
}
export const ExtraInfo: FC<{invite: Invite|null}> = (props) => {
  return <>
    {/* Extra info bg-[#fcf8f9] */}
    <section className={"px-8 py-16"}>
      <div className={`max-w-screen-md mx-auto`}>
        <h2 className={"font-serif font-bold text-xl"}>Aanvullende informatie</h2>
        <p className={"mt-3"}>
          We trouwen op 10 augustus 2024 bij de Phil in Tilburg.
        </p>
        <p className={"mt-3"}>
          Parkeren kan gratis op het terrein van de Phil. Er is voldoende parkeergelegenheid.
          Er zijn in de buurt meerdere hotels te vinden indien je wilt overnachten.
        </p>
        <p className={"mt-3"}>
          Gedurende de dag is er een trouw-fotograaf aanwezig die mooie fotos maakt.
          We zouden het fijn vinden als je tijdens de ceremonie zelf geen foto's maakt.
          De rest van de dag juichen we dit juist toe en hopen we dat je ze met ons deelt!
        </p>
        <p className={"mt-3"}>
          De dresscode is tenue de ville, met een voorkeur voor vrolijke zomerse kleuren en pastelkleuren.
        </p>
        <p className={"mt-3"}>
          Wanneer je ons graag een cadeau wilt geven, kun je onze cadeaulijst raadplegen: <a
          className={'text-blue-900 underline'} href={"https://www.lijstje.nl/10-augustus-2024"}>https://www.lijstje.nl/10-augustus-2024</a> deze werken we nog regelmatig bij. We hebben ook een enveloppendoos op de locatie staan.
          Maar je mag natuurlijk zelf ook iets bedenken!
        </p>
        <p className={"mt-3"}>
          Als je graag een stukje wilt doen of een speech wilt geven dan horen we dat graag!
        </p>
        <p className={"mt-3"}>
          Vragen of opmerkingen? Neem contact met ons op via <a className={'text-blue-900 underline'} href={"mailto:vragen@10-08-2024.wedding"}>vragen&#64;10-08-2024.wedding</a>.
        </p>
      </div>
    </section>
  </>
}
export const LocatieInfo: FC<{ invite: Invite | null }> = (props) => {
  return <>
    {/* LOCATIE */}
    <section
      className={"min-h-[70vh] flex items-center justify-center p-4 bg-[url('/philcrop.png')] lg:bg-[url('/phil.png')] bg-cover"}>
      <div className={"bg-offwhite p-4 rounded-lg w-full md:w-72"}>
        <span className={"text-sm"}>Locatie</span>
        <h2 className={"font-serif font-bold text-xl mb-3"}>De Phil, Tilburg</h2>
        <div className={"my-3"}>
          Er is voldoende gratis parkeergelegenheid op de locatie.
        </div>
        <div>
          Overnachten? <a
          href="https://www.google.nl/maps/search/Hotels/@51.5445603,5.054169,15z/data=!3m1!4b1!4m8!2m7!3m6!1sHotels!2sWijnstra+Catering,+Oude+Goirleseweg+167,+5025+SB+Tilburg!3s0x47c6be42efa9ae7b:0xa3b5d0be4e3ed2f9!4m2!1d5.0641962!2d51.5445486?entry=ttu"
          className={'text-blue-900 underline'} target={'_blank'}>Hotels in de buurt</a>
        </div>
        <div className={"my-3"}>
          Oude Goirleseweg 167,<br/>5025SB Tilburg
        </div>
        <a className={"rounded bg-blue-900 px-3 py-2 text-white font-medium flex items-center justify-center"}
           target={"_blank"}
           href={"https://www.google.nl/maps/place/Buitengewoon+de+PHIL/@51.5445486,5.0620075,17z/data=!3m1!4b1!4m5!3m4!1s0x47c6be3147e272bb:0x17515f721187d77c!8m2!3d51.5445486!4d5.0641962"}>
          <FontAwesomeIcon icon={faRoute} className={"mr-2"}/>
          Route plannen
        </a>
        <div></div>
      </div>
    </section>
  </>
}
