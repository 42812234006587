import React, {FC} from "react";
import {AnimatedInviteHero} from "../components/wedding/AnimatedInviteHero";
import {ScrollToBottomAnchor} from "../components/wedding/ScrollToBottomAnchor";
import {ExtraInfo, Info, LocatieInfo} from "../components/wedding/Info";
import {PictureBook} from "../components/wedding/PictureBook";

export const OverviewPage: FC = () => {
  return <div className={'scroll-smooth'}>
    <AnimatedInviteHero topOffset={0} invite={null}/>
    <ScrollToBottomAnchor shouldHint={false} />
    <Info invite={null} />
    <div className={"pb-3"} style={{
      backgroundImage: 'url("/papier.jpg")',
      // backgroundImage: 'url("/clothlg.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
      <ExtraInfo invite={null} />
      <PictureBook />
    </div>
    <LocatieInfo invite={null} />
  </div>
}
