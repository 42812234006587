import * as Sentry from "@sentry/react";
import config from "./config";

export default function initSentry() {
  if (config.sentryDsn !== '') {
    Sentry.init({
      dsn: config.sentryDsn,
    })
  } else {
    console.warn("Sentry is not enabled in this build. No error reporting available.")
  }
}