import React, {FC, useEffect, useState} from "react";
import {AnimatedInviteHero} from "../components/wedding/AnimatedInviteHero";
import {ScrollToBottomAnchor} from "../components/wedding/ScrollToBottomAnchor";
import {Invite} from "../api/dto";
import {useParams} from "react-router-dom";
import {useApiCall} from "../api/api";
import {AvailabilityWizard} from "../components/wedding/AvailabilityWizard";
import {ExtraInfo, Info, LocatieInfo} from "../components/wedding/Info";
import {PictureBook} from "../components/wedding/PictureBook";

export const InvitePage: FC = () => {
  const [invite, setInvite] = useState<Invite | null>(null)
  const {code} = useParams()
  const [,setLoading] = useState(true)
  const api = useApiCall()
  const [] = useState(false)
  useEffect(() => {
    if (code) {
      api.whoami(code).then((invite) => {
        setInvite(invite.data)
        setLoading(false)
      })
    }
  }, [code]);
  const [show, setShow] = useState(false)

  const hasConfirmed = invite?.has_confirmed_amount && (invite.is_invited_to_dinner === invite.has_confirmed_dinner_preferences)


  return <div className={'scroll-smooth'}>
    {(show && invite) ? <AvailabilityWizard close={() => setShow(false)} invite={invite} /> : <>
      <AnimatedInviteHero topOffset={0} invite={invite} onClick={() => setShow(old => !old)}/>
      <ScrollToBottomAnchor shouldHint={!hasConfirmed}/>
      <Info invite={invite}/>
      <div className={"pb-3"} style={{
        backgroundImage: 'url("/papier.jpg")',
        // backgroundImage: 'url("/clothlg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <ExtraInfo invite={invite}/>
        <PictureBook/>
      </div>
      <LocatieInfo invite={invite}/>
    </>}
  </div>
}
