import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from "react";



interface RefreshControllerContextType {
  refresh: () => void
  latestRefresh: Date
}

const RefreshControllerContext = createContext({} as RefreshControllerContextType)

const RefreshControllerContextProvider: FC<{children: ReactNode}> = ({children}) => {
  const [latestRefresh, setLatestRefresh] = useState<Date>(new Date())
  const refresh = useCallback(() => {
    setLatestRefresh(new Date())
  }, [])
  return <RefreshControllerContext.Provider value={{
    refresh,
    latestRefresh,
  }}>{children}</RefreshControllerContext.Provider>
}
/**
 * This component should be placed at the top of the component tree
 * @param props
 * @constructor
 */
export const RefreshController: FC<{children: ReactNode}> = props => {
  return <RefreshControllerContextProvider>
    {props.children}
  </RefreshControllerContextProvider>
}

/**
 * Works like useEffect, but only runs when the refresh function is called
 * @param effect
 * @param dependencies
 */
export const useRefreshEffect = (effect: () => void, dependencies: any[] = []) => {
  const {latestRefresh} = useContext(RefreshControllerContext)
  useEffect(() => {
    effect()
  }, [latestRefresh, ...dependencies])
}

/**
 * Returns the refresh function, this will refresh all components that use useRefreshEffect
 */
export const useRefresh = () => {
  const {refresh} = useContext(RefreshControllerContext)
  return refresh
}