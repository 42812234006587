import {useCallback} from "react";
import config from "../config";
import {Invite} from "./dto";

const API_BASE_URL = config.apiBaseUrl

export const useApiCall = () => {
  const checkForValidationErrors = async (response: Response): Promise<void> => {
    if (response.status === 422) {
      const json = await response.json()
      if ("message" in json && "errors" in json && typeof json.errors === "object") {
        throw new ValidationError(json.message, json.errors)
      }
      if ("message" in json) {
        throw new ValidationError(json.message, {'message': [json.message]})
      }
    }
  }

  const get = useCallback(async function<T>(url: string, code: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${code}`
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const post = useCallback(async function<T>(url: string, code: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${code}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const del = useCallback(async function<T>(url: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])

  return {
    whoami(code: string) {
      return get<Invite>('whoami', code)
    },
    confirmAvailability(code: string, available: number) {
      return post<any>('confirm-availability', code, {
        available: available
      })
    },
    confirmDinnerPreferences(code: string, preferences: object) {
      return post<any>('confirm-dinner-preferences', code, {
        preferences: preferences,
      })
    },
    addNote(code: string, note: string) {
      return post<any>('add-note', code, {
        note: note
      })
    }
  }
}

export class ValidationError extends Error {
  constructor(public readonly message: string, public readonly errors: { [key: string]: string[] }) {
    super(message);
  }
}
