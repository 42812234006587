import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {AuthProvider} from "./auth/AuthProvider";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {APIContextProvider} from "./api/APIContext";
import {ModalProvider} from "./components/layout/ModalProvider";
import initSentry from "./sentry";
import colors from "tailwindcss/colors";
import {RefreshController} from "./components/RefreshController";
import {OverviewPage} from "./pages/OverviewPage";
import {generateDynamicColorSchemeCSS} from "./util/colorScheme";
import {InvitePage} from "./pages/InvitePage";
import {Upload} from "./pages/Upload";
import {Download} from "./pages/Download";

initSentry()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider>
    <React.StrictMode>
      <style>{generateDynamicColorSchemeCSS(colors.cyan)}</style>
      <BrowserRouter>
        <RefreshController>
          {/*<AuthMiddleware>*/}
            <APIContextProvider>
              <ModalProvider>
                <Routes>
                  <Route path={"/"} element={<OverviewPage/>}/>
                  <Route path={"/uitnodiging/:code"} element={<InvitePage/>}/>
                  <Route path={"/fotoalbum/toevoegen"} element={<Upload/>}/>
                  <Route path={"/download"} element={<Download/>}/>
                </Routes>
              </ModalProvider>
            </APIContextProvider>
          {/*</AuthMiddleware>*/}
        </RefreshController>
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>,
)
