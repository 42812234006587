import {FC, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

export const ScrollToBottomAnchor: FC<{shouldHint: boolean}> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [scrollPos, setScrollPos] = useState(0)
  useEffect(() => {
    const scrollHandler = () => {
      setScrollPos((ref.current?.getBoundingClientRect().y ?? 0) - window.innerHeight)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [ref.current]);
  const hasScrolled = scrollPos < 100
  const [helpTimerPassed, setTimerPassed] = useState(false)
  const [helpTimer2Passed, setTimer2Passed] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimerPassed(true)
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimer2Passed(true)
    }, 10000)
    return () => {
      clearTimeout(timer)
    }
  }, [])
  return <>
    {! hasScrolled && <>
      {helpTimerPassed && props.shouldHint ? <>
        <button
          className={`scroll-smooth fixed bottom-2 left-1/2 -translate-x-1/2 rounded-lg w-[calc(100%-1rem)] lg:w-auto bg-blue-900 text-white px-8 py-3 text-lg ${helpTimer2Passed ? 'animate-pulse' : ''}`}
          onClick={() => ref.current?.scrollIntoView({block: 'end'})}>
          Aanwezigheid doorgeven
        </button>
      </> : <>
        <button
          className={'scroll-smooth fixed bottom-2 left-1/2 -translate-x-1/2 h-8 w-8 rounded-full bg-black opacity-50 text-white'}
          onClick={() => ref.current?.scrollIntoView({block: 'end'})}>
          <FontAwesomeIcon icon={faChevronDown}/>
        </button>
      </>}
    </>}
    <div ref={ref} className={''}></div>
  </>
}
