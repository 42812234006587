import {Invite} from "../../api/dto";
import {FC} from "react";

export const InviteCardContents: FC<{invite: Invite}> = ({invite}) => {
  // return <div></div>
  return <div className={"w-full text-[8pt] leading-snug md:leading-normal md:text-sm font-serif italic p-4"}>
    Beste {invite.names},
    <br/><br/>
    Wij gaan op <strong>10 augustus</strong> trouwen en we zouden het geweldig vinden
    als {invite.amount === 1 ? 'jij' : 'jullie'} erbij {invite.amount === 1 ? 'bent' : 'zijn'}!
    <br/><br/>
    We willen {invite.amount === 1 ? 'je' : 'jullie'} graag uitnodigen voor {generateInviteText(invite)}.
    <br/>
    <br/>
    De dresscode is tenue de ville, met een voorkeur voor vrolijke zomerse kleuren en pastelkleuren.
    <br/>
    <br/>
    Liefs, Anne-Marie en Jesse
  </div>
}

function generateInviteText(invite: Invite): string {
  if (!invite.is_invited_to_ceremony && !invite.is_invited_to_dinner && invite.is_invited_to_party) {                   // 0 0 1
    return "ons feest! je bent uitgenodigd vanaf 20:00 en de festiviteiten duren tot 01:00";
  }
  //                                                                                                               SKIPPED 0 1 0
  //                                                                                                               SKIPPED 0 1 1
  if (invite.is_invited_to_ceremony && !invite.is_invited_to_dinner && !invite.is_invited_to_party) {                   // 1 0 0
    return "onze ceremonie en aansluitende receptie om 15:00 tot 17:30 (inloop 14:30)";
  }
  //                                                                                                               SKIPPED 1 0 1
  if (invite.is_invited_to_ceremony && invite.is_invited_to_dinner && !invite.is_invited_to_party) {                    // 1 1 0
    return "onze ceremonie om 15:00 (inloop 14:30), aansluitende receptie en diner";
  }
  if (invite.is_invited_to_ceremony && invite.is_invited_to_dinner && invite.is_invited_to_party) {                     // 1 1 1
    return "onze speciale dag. De ceremonie start om 15:00 (inloop 14:30) en de festiviteiten duren tot 01:00";
  }

  return 'ons feest, maar we hebben een foutje gemaakt. Neem alsjeblieft contact met ons op.';
}
