import React, {FC, useEffect, useRef, useState} from "react";
import {AnimatedInviteHero} from "../components/wedding/AnimatedInviteHero";
import {ScrollToBottomAnchor} from "../components/wedding/ScrollToBottomAnchor";
import {Invite} from "../api/dto";
import {useParams, useSearchParams} from "react-router-dom";
import {useApiCall} from "../api/api";
import {AvailabilityWizard} from "../components/wedding/AvailabilityWizard";
import {ExtraInfo, Info, LocatieInfo} from "../components/wedding/Info";
import {PictureBook} from "../components/wedding/PictureBook";
import {Button} from "../components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircleNotch, faDownload, faUpload} from "@fortawesome/free-solid-svg-icons";

export const Download: FC = () => {
  const [params] = useSearchParams()
  const paths: string[] = Object.values(JSON.parse(atob(params.get('token') ?? 'Int9Ig==' /* is base64 json for: {} */)))

  return <StepA filePaths={paths} />
}

const StepA: FC<{filePaths: string[]}> = props => {
  const getPath = (p: string) => '/api/fotoalbum/download?path=' + encodeURIComponent(p);
  return <div className={'flex flex-col items-center justify-center p-4 bg-[#A9c5e0]'}>
    <div id={'downloads'} className={'max-w-sm flex flex-col items-center justify-center space-y-8'}>
      <img src="/menu%20logo.png" loading={'lazy'} alt="💍" className={"h-24 w-24 opacity-75"}/>
      <h1 className={'text-3xl font-bold block'}>Foto's downloaden</h1>
      <p className={'text-center opacity-75'}>Leuk dat je fotos hebt gemaakt. Download ze door op de fotos te klikken.</p>
      {props.filePaths.map((p, i) => {
        return <a className={'relative'} download={p} href={getPath(p)}>
          <div className={'h-24 w-24 bg-white bg-opacity-75 rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center'}>
            <FontAwesomeIcon icon={faDownload} className={'text-blue-900 text-3xl'}/>
          </div>
          <img key={i} src={getPath(p)}/>
        </a>;
      })}
    </div>
  </div>
}
