import React, {FC, useEffect, useRef, useState} from "react";
import {useInterval} from "../../util/useInterval";
import {Invite} from "../../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {InviteCardContents} from "./InviteCardContents";

export const AnimatedInviteHero: FC<{topOffset: number, invite: Invite|null, onClick?: () => void}> = ({topOffset,invite, onClick}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerHeight, setContainerHeight] = useState(-1)
  const [viewPortHeight, setViewPortHeight] = useState(-1)
  const [viewPortWidth, setViewPortWidth] = useState(-1)
  const [scrollPos, setScrollPos] = useState(0)
  const scrollProgress = (scrollPos - topOffset) / (containerHeight - viewPortHeight)

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight)
    }
  }, [containerRef.current]);

  useEffect(() => {
    setScrollPos(window.scrollY)
    setViewPortHeight(window.innerHeight)
    setViewPortWidth(window.innerWidth)
    const scrollHandler = () => {
      setScrollPos(window.scrollY)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])
  useEffect(() => {
    // setViewPortHeight(window.outerHeight)
    setViewPortWidth(window.innerWidth)
    const resizeHandler = () => {
      // setViewPortHeight(window.outerHeight)
      setViewPortWidth(window.innerWidth)
      setContainerHeight(containerRef.current?.clientHeight ?? 0)
    }
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [containerRef.current])

  // Turning card
  const [turningCardProgress, setTurningCardProgress] = useState(0)
  const [turningCardSpeed, setTurningCardSpeed] = useState(0)
  const hasConfirmed = invite?.has_confirmed_amount && (invite.is_invited_to_dinner === invite.has_confirmed_dinner_preferences)


  const render = () => {
    if (Math.abs(turningCardProgress - scrollProgress) < 0.01) {
      setTurningCardSpeed(0)
      setTurningCardProgress(scrollProgress)
      return
    }
    if (turningCardProgress < scrollProgress) {
      setTurningCardSpeed(0.10 * Math.abs(scrollProgress - turningCardProgress))
    } else if (turningCardProgress > scrollProgress) {
      setTurningCardSpeed(-0.10 * Math.abs(scrollProgress - turningCardProgress))
    }
    setTurningCardProgress(Math.min(Math.max(turningCardProgress + turningCardSpeed, 0), 1))
  }
  useInterval(() => render(), 1000/120)

  return <section ref={containerRef} className={'h-[150svh] overflow-y-hidden relative'} style={{
    backgroundImage: 'url("/papier.jpg")',
    // backgroundImage: 'url("/clothlg.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }}>
    {/*<img src="/menu%20logo.png" loading={'lazy'} alt="💍"*/}
    {/*     className={"hidden md:block md:absolute md:top-8 md:left-8 h-24 w-24 z-10"}/>*/}
    <ParalaxBackground
      containerHeight={containerHeight}
      viewPortHeight={viewPortHeight}
      viewPortWidth={viewPortWidth}
      bottomMargin={30}
      scrollPos={scrollPos}
      scrollProgress={turningCardProgress}
    />
    <TurningCard
      containerHeight={containerHeight}
      viewPortHeight={viewPortHeight}
      viewPortWidth={viewPortWidth}
      bottomMargin={30}
      scrollPos={scrollPos}
      scrollProgress={turningCardProgress}
      rearSide={<div className={'w-full h-full bg-white'}>
        <img src="/rear-invite-card.png" loading={'lazy'} alt="uitnodiging" className={'object-contain scale-x-[-1]'}/>
      </div>}
      frontSide={<div className={'w-full h-full bg-offwhite flex flex-col items-stretch'}>
        {invite ? <>
          <div className={'flex-1 flex items-center justify-center relative'}>
            <img src={'/menu logo.png'} alt="logo"
                 className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 object-contain opacity-5'}/>
            {invite ? <InviteCardContents invite={invite}/> :
              <FontAwesomeIcon icon={faCircleNotch} spin={true} className={'text-blue-900 text-4xl'}/>}
          </div>
          {!hasConfirmed && <button className={"bg-blue-950 hover:bg-blue-900 text-white m-2 text-sm md:text-base py-1 rounded"}
                   onClick={onClick}>
            Aanwezigheid doorgeven
          </button>}
        </> : <NoInvitePlaceholder />}
      </div>}
    />
  </section>
}

const TurningCard: FC<{
  containerHeight: number,
  viewPortHeight: number,
  viewPortWidth: number,
  bottomMargin: number,
  scrollPos: number,
  scrollProgress: number,
  rearSide: JSX.Element,
  frontSide: JSX.Element
}> = (props) => {
  const defaultHeight = 540
  const defaultWidth = 360
  // const defaultHeight = 360
  // const defaultWidth = 240
  const margin = 20
  const width = Math.min(defaultWidth, props.viewPortWidth*0.65 - margin)
  const height = Math.min(defaultHeight, width / defaultWidth * defaultHeight)
  const initialRotation = 20
  const initialScale = 1
  // const finalScale = 2
  const finalScale = 1.5
  // Calculation
  const scaledHeight = height * (props.scrollProgress * (finalScale - initialScale) + initialScale)
  const offsetFromTop = props.scrollProgress * (props.containerHeight - props.bottomMargin - scaledHeight - (props.viewPortHeight/2) + (height / 2)) + (props.viewPortHeight/2)
  return <div className={"absolute rounded-lg overflow-hidden shadow-2xl"} style={{
    height: `${height}px`,
    width: `${width}px`,
    top: offsetFromTop,
    left: '50%',
    transformOrigin: '50% 0%',
    transform: `rotateZ(${Math.max(Math.min(initialRotation - 2*initialRotation*props.scrollProgress), 0)}deg) translateX(-50%) translateY(-50%) scale(${Math.min(Math.max(props.scrollProgress*finalScale, 1), 2)}) rotateY(${Math.min(180, Math.floor(props.scrollProgress*180)) - 180}deg)`
  }}>
    {props.scrollProgress < 0.5 ? props.rearSide : props.frontSide}
  </div>
}

const ParalaxBackground: FC<{containerHeight: number, viewPortHeight: number, viewPortWidth: number, bottomMargin: number, scrollPos: number, scrollProgress: number}> = props => {
  const defaultHeight = props.viewPortHeight*0.95
  const defaultWidth = props.viewPortWidth*0.95
  const margin = 20
  const width = Math.min(defaultWidth, props.viewPortWidth * 0.95 - margin)
  const height = Math.min(defaultHeight, width / defaultWidth * defaultHeight)
  const initialScale = 1
  const finalScale = 1
  // Calculation
  const scaledHeight = height * (props.scrollProgress * (finalScale - initialScale) + initialScale)
  const offsetFromTop = (props.scrollProgress * 0.5) * (props.containerHeight - props.bottomMargin - scaledHeight - (props.viewPortHeight / 2) + (height / 2)) + (props.viewPortHeight / 2)
  return <div className={"absolute rounded-lg"} style={{
    height: `${height}px`,
    width: `${width}px`,
    top: offsetFromTop,
    left: '50%',
    transformOrigin: '50% 0%',
    transform: `translateX(-50%) translateY(-50%)`
  }}>
    <div className={"h-full w-full relative"}>
      {/* Top left asset*/}
      <img src="/links-boven-maar-dan-omgedraaid.png" loading={'lazy'} alt="" className={'rotate-180 w-[70vw] lg:w-auto lg:h-[35vh] absolute top-0 left-0'}/>
      <img src="/he-wat-klef.png" loading={'lazy'} alt="" className={'h-[20vh] lg:h-[34vh] absolute -rotate-6 bottom-[10vh] lg:bottom-0 left-0'}/>
      <img src="/kabouterpost.png" loading={'lazy'} alt="" className={'h-[30vh] lg:h-[50vh] absolute bottom-[60vh] lg:bottom-[50vh] rotate-12 -right-[10vw] lg:right-[10vw]'}/>
      <img src="/postzegels.png" loading={'lazy'} alt="" className={'h-[10vh] lg:h-[14vh] absolute bottom-[55vh] lg:bottom-[45vh] -right-[5vw] lg:right-[20vw]'}/>
      <img src="/ringen.png" loading={'lazy'} alt="" className={'h-[8vh] absolute hidden lg:block lg:bottom-[30vh] lg:right-[20vw]'}/>
      <img src="/een-soort-schotoltje.png" loading={'lazy'} alt="" className={'h-[20vh] absolute top-[30vh] left-0 lg:top-[36vh] lg:left-[3vw]'}/>
      <img src="/geile-bloem.png" loading={'lazy'} alt="" className={'h-[20vh] absolute bottom-[30vh] left-[70vw] lg:left-[15vw]'}/>
      <img src="/rechts-onder-maar-beter.png" loading={'lazy'} alt="" className={'w-[50vw] lg:w-auto lg:h-[40vh] absolute bottom-0 right-0'}/>
    </div>
  </div>
}

const NoInvitePlaceholder: FC = () => {
  return <div className={"flex flex-col items-stretch p-4 h-full max-h-full"}>
    <div className={'flex-1 rounded overflow-hidden bg-blue-100'}>
      <img src="/paris.jpg" alt="" className={"object-cover object-center"}/>
    </div>
    <span className={'text-sm mt-3 italic'}>Verliefd in Parijs, september 2022</span>
    {/*<img src="/heel-cute.jpg" alt="" className={"rounded"}/>*/}
    {/*<div className={"text-[6pt] md:text-xs mt-2 tracking-tight font-light"}>Foto: <i>Net verloofd</i>, 3 juni 2022</div>*/}
    {/*<div className={"font-serif font-bold text-center md:leading-loose flex-1 flex items-center justify-center italic"}>*/}
    {/*  Jesse en Anne-Marie<br/>gaan trouwen!*/}
    {/*</div>*/}
  </div>
}
