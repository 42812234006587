import React, {FC, useContext, useEffect, useState} from "react";
import {UnauthenticatedPlaceholder} from "../components/layout/UnauthenticatedPlaceholder";
import {AuthenticatedLayout} from "../components/layout/AuthenticatedLayout";

export const AuthMiddleware: FC<{children: React.ReactNode}> = ({children}) => {
  const {isAuthenticated} = useContext(AuthContext)
  if (! isAuthenticated) {
    return <UnauthenticatedPlaceholder />
  }
  return <AuthenticatedLayout>{children}</AuthenticatedLayout>
}

interface AuthContextType {
  isAuthenticated: boolean
}
export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType)
export const AuthProvider: FC<{children: React.ReactNode}> = ({children}) => {
  return <AuthContext.Provider value={{
    isAuthenticated: false
  }}>
    {children}
  </AuthContext.Provider>
}

function setToken(token: string|null): void {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return
  }
  if (token === null) {
    window.localStorage.removeItem('session')
  } else {
    window.localStorage.setItem("session", token)
  }
}
export function getToken(): string|null {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return null
  }
  return window.localStorage.getItem("session")
}
