import React, {FC, useEffect, useRef, useState} from "react";
import {AnimatedInviteHero} from "../components/wedding/AnimatedInviteHero";
import {ScrollToBottomAnchor} from "../components/wedding/ScrollToBottomAnchor";
import {Invite} from "../api/dto";
import {useParams} from "react-router-dom";
import {useApiCall} from "../api/api";
import {AvailabilityWizard} from "../components/wedding/AvailabilityWizard";
import {ExtraInfo, Info, LocatieInfo} from "../components/wedding/Info";
import {PictureBook} from "../components/wedding/PictureBook";
import {Button} from "../components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircleNotch, faUpload} from "@fortawesome/free-solid-svg-icons";

export const Upload: FC = () => {
  const [step, setStep] = useState<'a'|'b'>('a')
  const [files, setFiles] = useState<File[]>([])

  const [uploading, setUploading] = useState<{[i: string]: boolean}>({})


  const onFilesSelected = (files: File[]) => {
    setFiles(files)
    setStep('b')
    setUploading(files.reduce((acc, _, i) => ({...acc, [`${i}`]: true}), {}))
    files.forEach((file, i) => {
      const formData = new FormData()
      formData.append('file', file)
      fetch('/api/fotoalbum/upload', {
        method: 'POST',
        body: formData,
      }).then(() => {
        setUploading(x => ({...x, [`${i}`]: false}))
      })
    })
  }

  if (step === 'a') {
   return <StepA onFilesSelected={onFilesSelected} />
  }
  if (step === 'b') {
    return <StepB uploading={uploading} />
  }
  return <></>
}

const StepA: FC<{onFilesSelected: (files: File[]) => void}> = props => {
  const uploadRef = useRef<HTMLInputElement>(null)
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      props.onFilesSelected(Array.from(e.target.files))
      // const formData = new FormData()
      // formData.append('file', file)
      // await fetch('/api/fotoalbum/upload', {
      //   method: 'POST',
      //   body: formData,
      // })
    }
  }
  return <div className={'flex flex-col items-center justify-center p-4 bg-[#A9c5e0] fixed inset-0'}>
    <div className={'max-w-sm flex flex-col items-center justify-center space-y-8'}>
      <img src="/menu%20logo.png" loading={'lazy'} alt="💍" className={"h-24 w-24 opacity-75"}/>
      <h1 className={'text-3xl font-bold block'}>Foto's toevoegen</h1>
      <p className={'text-center opacity-75'}>Heb je leuke foto's gemaakt? Deel ze met het bruidspaar door ze te uploaden.</p>
      <button className={"rounded-lg px-4 py-3 text-white bg-blue-900 font-sans flex items-center justify-center w-full text-lg font-medium mt-8"} onClick={() => uploadRef.current?.click()}>
        <FontAwesomeIcon icon={faUpload} className={'mr-2'} />
        Upload
      </button>
      <input type='file' accept={'image/*'} multiple onChange={onFileChange} className={'hidden'} ref={uploadRef} />
    </div>
  </div>
}

const StepB: FC<{uploading: {[i: string]: boolean}}> = props => {
  const done = Object.values(props.uploading).filter(v => !v).length
  const total = Object.values(props.uploading).length
  return <div className={'flex flex-col items-center justify-center p-4 bg-[#A9c5e0] fixed inset-0'}>
    <div className={'max-w-sm flex flex-col items-center justify-center space-y-8'}>
      {done < total ? <>
        <FontAwesomeIcon icon={faCircleNotch} className={'text-blue-900 text-6xl animate-spin'} />
        <span className={"font-medium text-lg"}>{done} / {total}</span>
      </> : <>
        <FontAwesomeIcon icon={faCheckCircle} className={'text-blue-900 text-6xl'} />
        <span className={"font-medium text-lg"}>Dankjewel!</span>
      </>}
    </div>
  </div>
}
