import React, {FC} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Scrollbar, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
interface PictureBookItem {
  src: string
  alt: string
}
const items: PictureBookItem[] = [
  {src: "/fotoboek/IMG_0101.png", alt: "Oost-Souburg, 2017"},
  // {src: "/fotoboek/IMG_0102.png", alt: "0102"},
  {src: "/fotoboek/IMG_0103.png", alt: "Malta, 2017"},
  {src: "/fotoboek/IMG_0344.png", alt: "Bruxelles, 2023"},
  {src: "/fotoboek/IMG_1368.png", alt: "Amsterdam, 2017"},
  {src: "/fotoboek/IMG_1412.png", alt: "Ritthem, 2020"},
  {src: "/fotoboek/IMG_1815.png", alt: "Domaso, 2023"},
  {src: "/fotoboek/IMG_2152.png", alt: "Goes, 2018"},
  {src: "/fotoboek/IMG_2376.png", alt: "Dubrovnik, 2021"},
  {src: "/fotoboek/IMG_2402.png", alt: "Dubrovnik, 2021"},
  {src: "/fotoboek/IMG_2979.png", alt: "Roosendaal, 2022"},
  {src: "/fotoboek/IMG_3103.png", alt: "London, 2022"},
  {src: "/fotoboek/IMG_3159.png", alt: "London, 2022"},
  {src: "/fotoboek/IMG_3427.png", alt: "Vlissingen, 2022"},
  {src: "/fotoboek/IMG_3742.png", alt: "Vlissingen, 2024"},
  {src: "/fotoboek/IMG_3869.png", alt: "Paris, 2023"},
  {src: "/fotoboek/IMG_3950.png", alt: "Épernay, 2022"},
  {src: "/fotoboek/IMG_4032.png", alt: "Épernay, 2022"},
  {src: "/fotoboek/IMG_6461.png", alt: "Montecatini-Terme, 2019"},
  {src: "/fotoboek/IMG_6584.png", alt: "Sirmione, 2019"},
  {src: "/fotoboek/IMG_6606.png", alt: "Garda, 2019"},
  {src: "/fotoboek/IMG_1916.png", alt: "Lago di Como, 2023"},
  {src: "/fotoboek/IMG_3160.png", alt: "London, 2022"},
  {src: "/fotoboek/IMG_0063.png", alt: "Vijlen, 2022"},
  {src: "/fotoboek/IMG_1390.png", alt: "Kaatsheuvel, 2020"},
  {src: "/fotoboek/IMG_6105.png", alt: "Venlo, 2019"},
  {src: "/fotoboek/IMG_3932.png", alt: "London, 2018"},
  {src: "/fotoboek/IMG_2870.png", alt: "Antwerpen, 2022"},
].sort(() => Math.random() - 0.5)



export const PictureBook: FC = () => {

  // bg-[#bdd0c5]
  return <section className={"py-2"}>
    <div className={""}>

    </div>
    <Swiper
      modules={[Scrollbar, EffectCoverflow, Pagination]}
      spaceBetween={0}
      slidesPerView={Math.round(window.innerWidth / 300)}
      centeredSlides={true}
      speed={100}
      effect={'flip'}
      loop={true}
      autoplay
      // pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
    >
      {items.map((item, index) => <SwiperSlide key={index}>
        <div className={"h-[120vw] md:h-[350px] flex items-stretch"}>
          <div className={"bg-white shadow rounded-lg w-full my-4 mx-4 md:mx-2 p-4 flex flex-col items-stretch"}>
            <div className={"flex-1 rounded"} style={{backgroundImage: `url('${item.src}')`, backgroundSize: "cover", backgroundPosition: 'center'}}>
              {/*<img src={item.src} alt={item.alt} className={"object-cover"}/>*/}
            </div>
            <div className={"h-4 my-2 leading-snug font-bold text-right"}>
              {item.alt}
            </div>
          </div>
        </div>
      </SwiperSlide>)}
    </Swiper>
    <div className={"flex items-center justify-center space-x-3 text-blue-950"}>
      <FontAwesomeIcon icon={faArrowLeft} /> <span className={'text-lg'}>It all started with a swipe</span> <FontAwesomeIcon icon={faArrowRight} />
    </div>
  </section>
}
