import React, {FC, useCallback, useState} from "react";
import {useRefreshEffect} from "../components/RefreshController";


interface APIContextType {
  loading: boolean
}
export const APIContext = React.createContext<APIContextType>({} as APIContextType)

export const APIContextProvider: FC<{children: React.ReactNode}> = ({children}) => {


  useRefreshEffect(() => {

  })

  return <APIContext.Provider value={{
    loading: false
  }}>{children}</APIContext.Provider>
}

export function useApi() {
  return React.useContext(APIContext)
}

export function useFetchedResource<T, P = undefined>(get: (param: P) => Promise<T>) {
  const [resource, setResource] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchResource = useCallback(async (param: P, fetchSilently = false) => {
    if (!fetchSilently) {
      setLoading(true);
    }
    let fetchedResource = null
    try {
      fetchedResource = await get(param);
      setResource(fetchedResource);
    } catch (error) {
      // @ts-ignore
      setError(error);
    } finally {
      if (!fetchSilently) {
        setLoading(false);
      }
    }
    return fetchedResource
  }, []);

  return { resource, error, loading, reload: fetchResource };
}
